import { useState, useEffect } from "react"
import './homepage.css'
import Navbar from '../navbar/Navbar';
import About from '../about/about';
import Equipment from '../equipment/Equipment';
import Contact from '../contact/contact';
import Partnership from "../partnership/partnership";
import { FaFacebook, FaPhone } from "react-icons/fa";

export function Homepage() {
    const [media, setMedia] = useState('');

    useEffect(() => {
        const mediaQueries = [
            { size: 'xl', query: window.matchMedia("only screen and (min-width: 1200px)") },
            { size: 'l', query: window.matchMedia("only screen and (min-width: 992px) and (max-width: 1200px)") },
            { size: 'm', query: window.matchMedia("only screen and (min-width: 768px) and (max-width: 992px)") },
            { size: 'sm', query: window.matchMedia("only screen and (min-width: 576px) and (max-width: 768px)") },
            { size: 'xsm', query: window.matchMedia("only screen and (max-width: 576px)") },
        ]

        const match = mediaQueries.find(entry => entry.query.matches);
        setMedia(match.size)
       
        function handleChange(e) {
            const update = mediaQueries.find(entry => entry.query.matches);
            setMedia(update.size)
        }
        match.query.addEventListener("change", handleChange)
        return () => {
            match.query.removeEventListener("change", handleChange)
        }
    }, [media])

    return (
        <>
        <div className='homepage'>
            <Navbar size={media}/>
            <About size={media}/>
            <Equipment size={media}/>
            <Partnership />            
            <Contact size={media}/>

        </div>
        <div className="footer">
            <div className="footer-box">
            <div>
            <a href="https://www.facebook.com/GorillaAir?mibextid=ZbWKwL"><FaFacebook /></a>
            </div>
            
            <p>Designed by <a href="https://awc-home.vercel.app/">AWC</a></p>                
            </div>

        </div>
        </>
        
    )
}