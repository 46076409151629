import { Carousel } from "react-responsive-carousel"
import Image1 from "../../assets/Image1.jpeg"
import Image2 from "../../assets/Image2.jpeg"
import Image3 from "../../assets/Image3.jpeg"
import Image4 from "../../assets/Image4.jpeg"
import Image5 from "../../assets/Image5.jpeg"
import Image6 from "../../assets/Image6.jpeg"
import "./Equipment.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa"

const carouselArray = [
  <img className="carousel-image" src={Image1} alt="" />,
  <img className="carousel-image" src={Image2} alt="" />,
  <img className="carousel-image" src={Image3} alt="" />,
  <img className="carousel-image" src={Image4} alt="" />,
  <img className="carousel-image" src={Image5} alt="" />,
  <img className="carousel-image" src={Image6} alt="" />
]

export default function Equipment(props) {
  return (
    <div className="equipment-carousel" id="Equipment">
      <Carousel
        infiniteLoop={true}
        swipeable={props.size === 'sm' || props.size === 'xsm' ? true : false}
        centerMode={true}
        centerSlidePercentage={props.size === 'sm' || props.size === 'xsm' ? "100" : "40"}
        renderArrowPrev={(showPrev, hasPrev) => {
          return (
            hasPrev && (
              <button className="prev-btn" onClick={showPrev}>
                <FaArrowCircleLeft className="car-arrow"/>
              </button>
            )
          )
        }}
        renderArrowNext={(showNext, hasNext) => {
          return (
            hasNext && (
              <button className="next-btn" onClick={showNext}>
                <FaArrowCircleRight className="car-arrow"/>
              </button>
            )
          )
        }}
      >
        {carouselArray.map((image, index) => {
          return <div key={index}>{image}</div>
        })}
      </Carousel>
    </div>
  )
}
