import "./about.css"
import { cardArray } from "./aboutInfo"

export default function About(props) {

  if (props.size === "sm" || props.size === "xsm")
    return (
      <div className="about-container sm" id="About">
        { cardArray.map((card, index) => (
          <div className="card sm" key={ index }>
            <span className="about-header sm">{ card.header }</span>
            <span>{ card.text }</span>
          </div>
        )) }
      </div>
    )

  else return (
    <div className="about-container" id="About">
      { cardArray.map((card, index) => (
        <div className="card" key={ index }>
          <span className="about-header">{ card.header }</span>
          <span>{ card.text }</span>
        </div>
      )) }
    </div>
  )
}
