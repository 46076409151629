import { useState } from "react"
import "./Navbar.css"
import gorillaLogo from "../../assets/logoWhite.png"
import gorillaRed from "../../assets/logoRed.png"
import Hamburger from "hamburger-react"

export default function Navbar(props) {
  const [isOpen, setOpen] = useState(false)

  function scrollTo(id) {
    const eleID = document.getElementById(id)
    window.scroll({
      top: eleID.offsetTop,
      behavior: "smooth",
    })
  }

  if (props.size === "sm" || props.size === "xsm") 
    return (
      <div className="navbar sm">
        <img src={ gorillaRed } alt="Logo" />
        <div className='navbuttons sm'>
          <Hamburger toggled={ isOpen } toggle={ setOpen } />
          { isOpen ? (
            <>
              <button onClick={ () => scrollTo("About") }>About Us</button>
              <button onClick={ () => scrollTo("Equipment") }>Equipment</button>
              <button onClick={ () => scrollTo("Partnership") }>Partnerships</button>              
              <button onClick={ () => scrollTo("Contact") }>Contact</button>
            </>
          ) : null }
        </div>
      </div>
    )

  else return (
    <div className="navbar">
      <img src={ gorillaLogo } alt="Logo" />
      <div className="navbuttons">
        <button onClick={ () => scrollTo("About") }>About Us</button>
        <button onClick={ () => scrollTo("Equipment") }>Equipment</button>
        <button onClick={ () => scrollTo("Partnership") }>Partnership</button>        
        <button onClick={ () => scrollTo("Contact") }>Contact</button>
      </div>
    </div>
  )
}
