import { useForm } from "react-hook-form"
import redLogo from "../../assets/logoRed.png"
import whiteLogo from "../../assets/logoWhite.png"
import "./contact.css"
import { ContactFormGroup } from "./contactFormGroup"
import { useEffect } from "react"

export default function Contact(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      comments: "",
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  async function onSubmit(data) {
    console.log(data)
    try {
      const dataFetch = await fetch("https://gorilla-air.com/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: data,
        }),
      })

      if (!dataFetch.ok) {
        throw "Error Processing Request"
      }

      const res = await dataFetch.json()
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  const size = props.size === "sm" || props.size === "xsm"

  return (
    <div className={size ? "contact sm" : "contact"} id="Contact">
      <div className="title-logo">
        <img src={whiteLogo} alt="" />
        <h2>Contact Us</h2>
      </div>
      <div className={size ? "contact-card sm" : "contact-card"}>
        <p className="call-now">
          Call Us Now At:{" "}
          {size ? <a href="tel:123-456-7890">228-574-8770</a> : "228-574-8770"}{" "}
          <br />
          For a free site evaluation, or to notify us of any problems with our
          equipment,
          <br /> complete and submit the form
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <ContactFormGroup errorMessage={errors?.name?.message}>
            <label className="contact-label" htmlFor="name">
              Name:{" "}
            </label>
            <input
              id="name"
              {...register("name", {
                required: { value: true, message: "Name Required" },
                validate: (value) => {
                  if (value.match(/\d/)) return "Cannot include numbers"
                },
              })}
            />
          </ContactFormGroup>
          <ContactFormGroup errorMessage={errors?.email?.message}>
            <label className="contact-label" htmlFor="email">
              Email:{" "}
            </label>
            <input
              id="email"
              {...register("email", {
                required: { value: true, message: "Email Required" },
                validate: (value) => {
                  if (
                    !value.match(
                      /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/
                    )
                  ) {
                    return "Invalid Email"
                  }
                },
              })}
            />
          </ContactFormGroup>
          <ContactFormGroup errorMessage={errors?.phone?.message}>
            <label className="contact-label" htmlFor="number">
              Phone:{" "}
            </label>
            <input
              id="number"
              {...register("phone", {
                required: { value: true, message: "Phone Number Required" },
                minLength: {
                  value: 10,
                  message: "Must have at least 10 characters",
                },
                maxLength: {
                  value: 11,
                  message: "Cannot have more than 11 characters",
                },
                validate: {
                  hasLetters: (value) => {
                    if (value.match(/[a-z]/) || value.match(/[A-Z]/)) {
                      return "Must only include number 0-9"
                    }
                  },
                },
              })}
            />
          </ContactFormGroup>
          <ContactFormGroup errorMessage={errors?.address?.message}>
            <label className="contact-label" htmlFor="address">
              Address:{" "}
            </label>
            <input
              id="address"
              {...register("address", {
                required: { value: true, message: "Address Required" },
              })}
            />
          </ContactFormGroup>
          <ContactFormGroup errorMessage={errors?.comments?.message}>
            <label className="contact-label" htmlFor="comments">
              Comments:{" "}
            </label>
            <input
              id="comments"
              {...register("comments", {
                required: { value: false },
                maxLength: {
                  value: 250,
                  message: "Cannot exceed 250 characters",
                },
              })}
            />
          </ContactFormGroup>
          <input type="submit" className="submit" />
        </form>
      </div>
    </div>
  )
}
