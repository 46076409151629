import "./partnership.css"
import { partnershipArray } from "./partnershipList"

export default function Partnership() {
  return (
    <div className="partnership-container" id="Partnership">
      <span className="partnership-header">Why Partner with Gorilla Air?</span>
      <span className="partnership-text">
        Gorilla Air is the leading provider of high-quality air compressors for
        convenience stores. We offer a range of benefits for our partners, such as:
      </span>
      <ul>
        {partnershipArray.map((entry, index) => (
          <li className="entry" key={index}>
            <span className="entry-header">{entry.header}</span>
            <span>{entry.text}</span>
          </li>
        ))}
      </ul>
      <span className="partnership-text">
        By partnering with Gorilla Air, you can increase your customer
        satisfaction, loyalty, and retention. You can also boost your income and
        reputation with our reliable and efficient air compressors.
      </span>
    </div>
  )
}
