export const cardArray = [
    {
        header: "About Us",
        text: "Gorilla Air is a company specializing in setting up mutually beneficial partnership deals with convenience store owners across the southeast. We believe in shared success and aim to transform the way convenience stores operate. Our mission is to empower convenience store owners while enhancing the driving experience for every customer."
    },
    {
        header: "Our Commitment",
        text: "At Gorilla Air, we understand that the tire air business should be a win-win. That's why we offer a unique revenue-sharing model. You'll receive a percentage of the revenue generated from our air compressors, ensuring a substantial and steady income stream for your business."
    },
    {
        header: "Free Installation and Maintenance",
        text: "We make it easy for you to benefit from our services. Gorilla Air provides free installation and all ongoing maintenance on the equipment. This means zero upfront costs and no hassle for you. We take care of the equipment, so you can focus on running your convenience store."
    }     
]
